import { library } from "@fortawesome/fontawesome-svg-core"

import {
  faFolder,
  faCheckSquare,
  faCoffee,
  faSitemap,
  faImage,
  faFileImage,
  faRecycle,
  faTrash,
  faEdit,
  faThumbsUp,
  faHeading,
  faHandPointer,
  faHandshake,
  faCog,
  faMoneyBill,
  faFlag,
  faFont,
  faPlusCircle,
  faMinusCircle,
  faTimesCircle,
  faPause,
  faCheck,
  faArchive,
  faExclamation,
  faExclamationCircle,
  faClock,
  faRocket,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faNewspaper,
  faHome,
  faSlidersH,
  faDatabase,
  faTh,
  faExclamationTriangle,
  faPlus,
  faLevelDownAlt,
  faEnvelope,
  faUsers,
  faHourglassEnd,
  faLayerGroup,
  faQuestionCircle,
  faArrowCircleLeft,
  faArrowCircleRight,
  faCloudUploadAlt,
  faCloudDownloadAlt,
  faDollarSign,
  faHeart,
  faComment,
  faCommentAlt,
  faPaperPlane,
  faTimes,
  faAngleDown,
  faMobile,
  faEllipsisH,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCircle,
  faImages,
  faCheckCircle,
  faEye,
  faCalendarAlt,
  faTrashAlt,
  faGripHorizontal,
  faSlash,
  faFile,
  faFlagCheckered,
  faDesktop,
  faBars,
  faBookmark,
  faPalette,
  faUsersCog,
  faUserShield,
  faUserPlus,
  faUserMinus,
  faUser,
  faRedo,
  faGlobeAfrica,
  faShare,
  faCopy,
  faLink,
  faBullseye,
  faQuestion,
  faTags,
  faRemoveFormat,
  faHatCowboy,
  faFileInvoiceDollar,
  faLifeRing,
  faAt,
  faItalic,
  faInfoCircle,
  faLock,
  faTv,
  faLanguage,
  faTruckMoving,
  faChartLine,
  faPauseCircle,
  faPlay,
  faFaceSmile,
  faPhone,
  faGrip,
  faSquareCaretDown,
  faEllipsisVertical,
  faMapPin,
  faFilter,
} from "@fortawesome/free-solid-svg-icons"

import { faFacebookF, faInternetExplorer, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons"

library.add(
  faFolder,
  faSlash,
  faCheckSquare,
  faCoffee,
  faTimes,
  faAngleDown,
  faSitemap,
  faMapMarkedAlt,
  faImage,
  faFileImage,
  faRecycle,
  faMobile,
  faEllipsisH,
  faTrash,
  faEdit,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faThumbsUp,
  faHeading,
  faHandPointer,
  faHandshake,
  faCog,
  faMoneyBill,
  faFlag,
  faCircle,
  faFont,
  faEye,
  faPlusCircle,
  faMinusCircle,
  faTimesCircle,
  faPause,
  faCheck,
  faCheckCircle,
  faArchive,
  faExclamation,
  faClock,
  faRocket,
  faCalendarAlt,
  faTrashAlt,
  faMapMarkerAlt,
  faNewspaper,
  faFacebookF,
  faInternetExplorer,
  faHome,
  faSlidersH,
  faDatabase,
  faTh,
  faHeart,
  faComment,
  faCommentAlt,
  faPaperPlane,
  faImages,
  faFile,
  faGripHorizontal,
  faExclamationTriangle,
  faPlus,
  faLevelDownAlt,
  faFlagCheckered,
  faEnvelope,
  faUsers,
  faUser,
  faHourglassEnd,
  faLayerGroup,
  faQuestionCircle,
  faExclamationCircle,
  faArrowCircleLeft,
  faArrowCircleRight,
  faCloudUploadAlt,
  faCloudDownloadAlt,
  faDollarSign,
  faBars,
  faDesktop,
  faBookmark,
  faPalette,
  faUsersCog,
  faUserShield,
  faUserPlus,
  faUserMinus,
  faRedo,
  faGlobeAfrica,
  faShare,
  faCopy,
  faLink,
  faBullseye,
  faLinkedinIn,
  faQuestion,
  faTags,
  faRemoveFormat,
  faHatCowboy,
  faFileInvoiceDollar,
  faLifeRing,
  faAt,
  faItalic,
  faInfoCircle,
  faLock,
  faTv,
  faLanguage,
  faYoutube,
  faTruckMoving,
  faChartLine,
  faPauseCircle,
  faPlay,
  faFaceSmile,
  faPhone,
  faGrip,
  faSquareCaretDown,
  faEllipsisVertical,
  faMapPin,
  faFilter
)
